import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";
import 'leaflet/dist/leaflet.css';
import "./Contact.css";
import { FiLoader } from 'react-icons/fi';
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import banner from '../../assets/images/lobby.webp'
import { ParallaxBanner, ParallaxProvider } from "react-scroll-parallax";
import { useTranslation } from "react-i18next";

const Contact = () => {

  const { t } = useTranslation()

  // Fix for default marker icon
  delete L.Icon.Default.prototype._getIconUrl;

  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  });
  const position = [42.439854, 19.219708];
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const form = useRef();
  const [contactSuccessful, setContactSuccessful] = useState(false);
  const [loading, setLoading] = useState(false);
  const siteKey = "6Lcg_i4nAAAAAA_s_7KxHvFU9oatn0wSD3MoUToF";
  const sendEmail = (formData) => {
    setLoading(true);
    emailjs
      .sendForm(
        "service_02od35i",
        "template_bd61mr3",
        form.current,
        "m4ZJcoaXbsWF8NNvG",
      )
      .then(
        (result) => {
          if (result.text === "OK") {
            setLoading(false);
            setContactSuccessful(true);
          }
        },
        (error) => {
          alert("There has been an error in your request.");
          setLoading(false);
        }
      );
  };

  const onSubmit = async (formData) => {
    const token = await new Promise((resolve) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(siteKey, { action: "submit" }).then(resolve);
      });
    });
    formData.token = token; // Add the reCAPTCHA token to the form data
    sendEmail(formData);
  };

  return (
    <div className="contact-wrapper">
      <ParallaxProvider>
        <ParallaxBanner
          layers={[{ image: banner, speed: -15 }]}
          className="aspect-[2/1] h-72 md:h-96"
        />
        <div className="absolute top-0 right-0 z-10 pt-10 text-white flex justify-center items-center h-72 md:h-96 bg-black bg-opacity-40 w-screen m-auto">
          <div className="text-xl md:text-4xl font-bold-italic block">{t('contactUsBanner')}</div>
        </div>
      </ParallaxProvider>
      <div className="contact-info-wrapper">
        {/* Contact info */}
      </div>
      <div className="contact-container">
        {!contactSuccessful ?
          <div className="contact-form-section">
            <form ref={form} id="contact-form" onSubmit={handleSubmit(onSubmit)}>
              <select
                className="contact-form-input"
                style={{ appearance: "none" }}
                name="subject"
                {...register('subject', { required: true })}
              >
                <option value="">{t('subject')}</option>
                <option value="Članarina">{t('subscription')}</option>
                <option value="Zaposlenje">{t('job')}</option>
                <option value="Opšti upit">{t('other')}</option>
              </select>
              <div className="error-container">
                {errors.subject && <span className="text-alert">{t('subjectAlert')}</span>}
              </div>

              <input
                className="contact-form-input"
                type="text"
                name="from_name"
                placeholder={t('name')}
                {...register('name', { required: true })}
              />
              <div className="error-container">
                {errors.name && <span className="text-alert">{t('nameAlert')}</span>}
              </div>

              <input
                className="contact-form-input"
                type="email"
                name="email"
                placeholder={t('email')}
                {...register('email', {
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                })}
              />
              <div className="error-container">
                {errors.email && <span className="text-alert">{t('emailAlert')}</span>}
              </div>

              <textarea
                className="contact-form-input"
                name="message"
                placeholder={t('message')}
                {...register('message', { required: true })}
              />
              <div className="error-container">
                {errors.message && <span className="text-alert">{t('messageAlert')}</span>}
              </div>
              {/* <ReCAPTCHA sitekey="6Lcg_i4nAAAAAA_s_7KxHvFU9oatn0wSD3MoUToF" {...register('g-recaptcha-response', { required: true })} /> */}
              <div className="g-recaptcha scale-90" data-sitekey={siteKey}></div>{" "}
              {/* Add the reCAPTCHA element */}
              {errors.token && <p>{t('captchaAlert')}</p>}
              <div className="error-container">
                {errors["g-recaptcha-response"] && <span className="text-alert">{t('captchaAlertTwo')}</span>}
              </div>
              {
                !loading ?
                  <input
                    className="contact-form-submit"
                    type="submit"
                    value={t('sendMessage')}
                  />
                  :
                  <div className="contact-form-submit"><FiLoader /></div>
              }

            </form>
          </div> : <h2 style={{ textAlign: 'center' }}>{t('thanks')}<br /> {t('response')}</h2>}
        <div className="contact-image-section">
          <h1>
            <MapContainer style={{ height: "300px", width: '300px', margin: 'auto', zIndex: '0' }} center={position} zoom={18}>
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker position={position}>
                {/* You can also add a Popup here if you want */}
              </Marker>
            </MapContainer>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Contact;
