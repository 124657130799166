import React from 'react';
import { useTranslation } from 'react-i18next';
import img from '../assets/banner-pics/ivan-blog.webp';
import { ParallaxBanner, ParallaxProvider } from 'react-scroll-parallax';

const IvanBlog = () => {
  const { t } = useTranslation();

  return (
    <div>
      <ParallaxProvider>
        <ParallaxBanner
          layers={[{ image: img, speed: -15 }]}
          className="aspect-[2/1] h-72 md:h-96"
        />
      </ParallaxProvider>
      <div className="max-w-4xl mx-auto p-5 pt-5"> {/* Adjust pt value to ensure content does not overlap with the parallax image */}
        <h1 className="text-xl md:text-3xl font-bold text-center mb-6">{t('ivanBlog.ivanTitle')}</h1>
        <p className="mb-4 text-justify">{t('ivanBlog.ivanIntroduction')}</p>
        <h2 className="text-lg md:text-xl font-semibold mb-4">{t('ivanBlog.ivanTrainingConcept')}</h2>
        <p className="mb-4 text-justify">{t('ivanBlog.ivanTrainingBenefits')}</p>
        <h2 className="text-lg md:text-2xl font-semibold mb-4">{t('ivanBlog.ivanTrainingGroupsTitle')}</h2>
        <ul className="list-disc list-inside mb-4">
          {Object.entries(t('ivanBlog.ivanTrainingGroupsList', { returnObjects: true })).map(([key, value]) => (
            <li key={key}>{value}</li>
          ))}
        </ul>
        <footer className="mt-8 border-t pt-4">
          <p className="text-center text-sm text-gray-600">{t('ivanBlog.ivanAuthor')}</p>
        </footer>
      </div>
    </div>
  );
};

export default IvanBlog;
