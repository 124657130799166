import React, { useState } from "react";
import "./TrainerCard.css";
import { useTranslation } from 'react-i18next';

const TrainerCard = ({ trainer }) => {

  const { t } = useTranslation()

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  // Function to stop propagation for modal content clicks
  const handleModalContentClick = (e) => e.stopPropagation();

  return (
    <>
      <div className="trainer-wrapper cursor-pointer" onClick={toggleModal}>
        <img
          className="trainer-img"
          src={trainer.image}
          alt={trainer.name}
        />
        <h2 className="trainer-name">{trainer.name}</h2>
        <div className="trainer-bio">
          {trainer.bioShort}
        </div>
        <button
          className="learn-more-btn"
          onClick={toggleModal}
        >
          {t('learnMore')}
        </button>
      </div>

      {isModalOpen && (
        <div
          className="payment-backdrop fixed inset-0 faded z-50 overflow-auto bg-black bg-opacity-40 flex justify-center items-center p-4 fade-in" // Applied fade-in class here
          onClick={toggleModal}
        >
          <div
            className="bg-white p-5 rounded-lg max-w-4xl w-full space-y-4 flex flex-col md:flex-row items-center"
            onClick={handleModalContentClick}
          >
            <img
              src={trainer.image}
              alt={trainer.name}
              className="h-person md:pr-4"
            />
            <div className="text-left space-y-2 p-0 md:p-0">
              <h3 className="text-md md:text-lg font-bold">{trainer.name}</h3>
              <p className="text-xs md:text-md h-32 md:h-44 overflow-y-auto">{trainer.bioLong}</p>
              <button className="payment-dialog-close-button" onClick={toggleModal}>
                {t('close')}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TrainerCard;
