import React, { useState } from "react";
import "./PricingCardNew.css";
import { useTranslation } from "react-i18next";

const PaymentOption = ({
  title,
  price,
  period,
  features,
  buttonText,
  footnote,
  imageUrl,
  color,
  paymentPlans,
  special
}) => {

  const { t } = useTranslation();

  const [isDialogOpen, setDialogOpen] = useState(false);

  const openDialog = () => {
    setDialogOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeDialog = () => {
    setDialogOpen(false);
    document.body.style.overflow = 'unset';
  };

  return (
    <>
      <div className='payment-option'>
        <div className={`payment-option-header ${color} ${special && special}`}>{title}</div>
        <div className="payment-option-price">
          {price}€<span className="payment-option-period">/{period}</span>
        </div>
        <img src={imageUrl} alt="Payment Option Image" className="payment-option-image" />
        <ul className="payment-option-features">
          {features.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
        {!special && <button className="payment-option-button" onClick={openDialog}>
          {buttonText}
        </button> }

        <div className="payment-option-footnote">{footnote}</div>
      </div>

      {isDialogOpen && (
        <>
          <div className="payment-backdrop open" onClick={closeDialog}></div>
          <dialog className="payment-dialog open" role="dialog" aria-modal="true">
            <div className="payment-dialog-content">
              <div className={`payment-dialog-header ${color} bg-opacity-10 rounded-lg py-5`}>{title}</div>
              <div className="payment-dialog-options">
                <div className="payment-dialog-option">
                  <span>{t('threeMonthPricing')}</span>
                  <span>{paymentPlans.threeMonth[0]}€</span>
                </div>
                {/* <div className="payment-dialog-option">
                  <span>3 months with payment at start of month</span>
                  <span>{paymentPlans.threeMonth[1]}€</span>
                </div> */}
                <div className="payment-dialog-option">
                  <span>{t('sixMonthPricing')}</span>
                  <span>{paymentPlans.sixMonth[0]}€</span>
                </div>
                {/* <div className="payment-dialog-option">
                  <span>6 months with payment at start of month</span>
                  <span>{paymentPlans.sixMonth[1]}€</span>
                </div> */}
                {/* <div className="payment-dialog-option">
                  <span>12 months with payment at start of month</span>
                  <span>{paymentPlans.twelveMonth[1]}€</span>
                </div> */}
              </div>
              <button className="payment-dialog-close-button" onClick={closeDialog}>
                {t('close')}
              </button>
            </div>
          </dialog>
        </>
      )}
    </>
  );
};

export default PaymentOption;
