import React from 'react'
import PricingCardNew from '../views/Pricing/partials/PricingCardNew'
import gymBasicYearBanner from '../assets/banner-pics/pricing/gym-basic-year.webp'
import gymPremiumYearBanner from '../assets/banner-pics/pricing/gym-premium-year.webp'
import gymBasicBanner from '../assets/banner-pics/pricing/gym-basic.webp'
import gymPremiumBanner from '../assets/banner-pics/pricing/gym-premium.webp'
import groupBasicBanner from '../assets/banner-pics/pricing/group-basic.webp'
import groupPremiumBanner from '../assets/banner-pics/pricing/group-premium.webp'
import morningPremiumBanner from '../assets/banner-pics/pricing/morning-basic.webp'
import morningBasicBanner from '../assets/banner-pics/pricing/morning-premium.webp'
import { useTranslation } from 'react-i18next'
import SpecialPricingCard from '../views/Pricing/partials/SpecialPricingCard'

const ScheduleBanner = () => {
  const { t } = useTranslation();

  const premiumPricing =
  {
    threeMonth: ['165', '142.5'],
    sixMonth: ['290', '127.5'],
    twelveMonth: ['663.00', '112.5']
  }
  const basicPricing =
  {
    threeMonth: ['115', '213.75'],
    sixMonth: ['200', '191.1'],
    twelveMonth: ['429.00', '170.4']
  }
  const morningBasicPricing =
  {
    threeMonth: ['92', '213.75'],
    sixMonth: ['160', '191.1'],
    twelveMonth: ['364.65', '170.4']
  }
  const morningPremiumPricing =
  {
    threeMonth: ['132', '213.75'],
    sixMonth: ['232', '191.1'],
    twelveMonth: ['563.55', '170.4']
  }
  return (
    <div className=''>
      {/* <div className='pricing-wrapper w-full pb-10 special-offer-bg'>
        <div className="pricing-page-about">
          <div className='text-2xl pt-5'>
            <p className='text-center w-screen text-yellow-300 font-bold bg-dark-special py-4'>{t("goldPricingTitle")}</p>
          </div>
        </div>
        <div className="flex flex-wrap gap-16 md:w-10/12 xl:w-w-11/12 m-auto justify-center items-center pt-10">
        <SpecialPricingCard
          title={t("goldGymMorningBasic")}
          price="19.99"
          currency="€"
          timesPerMonth={t("goldGymMorningBasicPerMonth")}
          paymentOption={t("goldGymPremiumPayment")}
        />
          <SpecialPricingCard 
          title={t("goldGymMorningPremium")}
          price="34.99"
          currency="€"
          timesPerMonth={t("goldGymMorningPremiumBasicPerMonth")}
          paymentOption={t("goldGymPremiumPayment")}
          smallDesc={true}
        />
          <SpecialPricingCard 
          title={t("goldGymBasicTitle")}
          price="34.99"
          currency="€"
          timesPerMonth={t("goldGymBasicPerMonth")}
          paymentOption={t("goldGymBasicPayment")}
        />
          <SpecialPricingCard 
          title={t("goldGymPremiumTitle")}
          price="49.99"
          currency="€"
          timesPerMonth={t("goldGymPremiumPerMonth")}
          paymentOption={t("goldGymPremiumPayment")}
        />
        </div>
        <div className='mt-4 text-center text-xs md:text-md text-gray-50 bg-black bg-opacity-50 md:w-max py-1 px-2 m-auto'>{t("goldPricingFooterTip")}</div>
      </div> */}
      <div className='pricing-wrapper pb-5'>
        <div className="pricing-page-about">
          <h2>{t('monthlyTrainingPlans')}</h2>
          <div>{t('trainingPlanPrices')} <span className='text-green-500'>{t('dailyTrainingPrice')}</span></div>
        </div>
        <div className="pricing-list">
          <PricingCardNew
            imageUrl={gymBasicBanner}
            period={t('month')}
            buttonText={t('learnMore')}
            footnote={t('seePaymentOptions')}
            title={t('gymBasicTitle')}
            price='45'
            features={[
              t('features.3xWeekGym'),
              t('features.locker'),
              t('features.shower'),
              t('features.parking')
            ]}
            color='green'
            paymentPlans={basicPricing}
          />
          <PricingCardNew
            imageUrl={gymPremiumBanner}
            period={t('month')}
            buttonText={t('learnMore')}
            footnote={t('seePaymentOptions')}
            title={t('gymPremiumTitle')}
            price='65'
            features={[
              t('features.7xWeekGym'),
              t('features.locker'),
              t('features.shower'),
              t('features.parking')
            ]}
            color='green-plus'
            paymentPlans={premiumPricing}
          />
          <PricingCardNew
            imageUrl={groupBasicBanner}
            period={t('month')}
            buttonText={t('learnMore')}
            footnote={t('seePaymentOptions')}
            title={t('groupBasicTitle')}
            price='45'
            features={[
              t('features.3xWeekGroup'),
              t('features.locker'),
              t('features.shower'),
              t('features.parking')
            ]}
            color='pink'
            paymentPlans={basicPricing}
          />
          <PricingCardNew
            imageUrl={groupPremiumBanner}
            period={t('month')}
            buttonText={t('learnMore')}
            footnote={t('seePaymentOptions')}
            title={t('groupPremiumTitle')}
            price='65'
            features={[
              t('features.7xWeekGroup'),
              t('features.locker'),
              t('features.shower'),
              t('features.parking')
            ]}
            color='pink-plus'
            paymentPlans={premiumPricing}
          />
          <PricingCardNew
            imageUrl={morningBasicBanner}
            period={t('month')}
            buttonText={t('learnMore')}
            footnote={t('seePaymentOptions')}
            title={t('morningBasicTitle')}
            price='36'
            features={[
              t('features.3xWeekGymMorning'),
              t('features.locker'),
              t('features.shower'),
              t('features.parking')
            ]}
            color='blue'
            paymentPlans={morningBasicPricing}
          />
          <PricingCardNew
            imageUrl={morningPremiumBanner}
            period={t('month')}
            buttonText={t('learnMore')}
            footnote={t('seePaymentOptions')}
            title={t('morningPremiumTitle')}
            price='52'
            features={[
              t('features.7xWeekGymMorning'),
              t('features.locker'),
              t('features.shower'),
              t('features.parking')
            ]}
            color='blue-plus'
            paymentPlans={morningPremiumPricing}
          />
        </div>
        <p className='text-center md:text-xs w-10/12 m-auto'>{t('towelNotice')}<span className='text-green-500'> {t('towelNoticePrice')}</span></p>
      </div>
    </div>
  )
}

export default ScheduleBanner