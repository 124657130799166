import { createClient, groq } from 'next-sanity'

export async function getPosts() {
  const client = createClient({
    projectId: 'fsqhzy1o',
    dataset: 'production',
    apiVersion: '2023-05-15',
  })

  return client.fetch(
    groq`*[_type == "post"]{
      _id,
      _createdAt,
      important,
      name,
      "slug": slug.current,
      "image": image.asset->url,
      url,
      content
    }`
  )
}

export async function getPost(slug) {
  const client = createClient({
    projectId: 'fsqhzy1o',
    dataset: 'production',
    apiVersion: '2023-05-15',
  })

  return client.fetch(
    groq`*[_type == "post" && slug.current == $slug][0]{
      _id,
      _createdAt,
      important,
      name,
      "slug": slug.current,
      "image": image.asset->url,
      url,
      content
    }`,
    { slug }
  )
}
