import React, { useState, useEffect, useContext } from 'react';
import { FadeLoader } from 'react-spinners';
import { PostContext } from '../../context/PostContext';
import NewsCard from '../../components/NewsCard/NewsCard';
import './News.css'

const News = () => {

  const storePosts = useContext(PostContext);
  const posts = storePosts.slice(0, 3)
  const [loading, setLoading] = useState(false)

  // useEffect(() => {
  //   const fetchPosts = async () => {
  //     setLoading(true)
  //     const data = await getPosts();
  //     const latestNews = data.reverse()
  //     setPosts(latestNews.slice(0, 3));
  //     setLoading(false)
  //   };
  //   fetchPosts();
  // }, []);

  return (
    <>
      {
        !loading ?
          <div className="news">
            <h1>Novosti</h1>
            <div className='news-wrapper'>
              {posts.map((post) => (
                <NewsCard key={post._id} data={post} />
              ))}
            </div>
          </div> : <div className="news-loader"><FadeLoader color={'#ccc'} loading={true} size={150} /></div>
      }
    </>
  );
};

export default News;
