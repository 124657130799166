import React from 'react'
import './Therapy.css'
import { Parallax } from 'react-parallax'
import therapyBanner from '../../assets/images/cardio.webp'

const Therapy = () => {
  return (
    <div className='therapy-wrapper'>
      <Parallax strength={200} bgImage={therapyBanner}>
        <div className="program-page-banner" style={{ height: '45vh' }}>
          <h2 className='text-white' style={{ fontFamily: 'bold-italic' }}>RECOVERY AREA</h2>
        </div>
      </Parallax>
      <div className="pricing-page-about">
        <h2>Program za Oporavak</h2>
        <div>Naša Recovery Area je opremljena vrhunskom opremom i stručnim osobljem koje će vam pomoći da postignete najbolje rezultate u vašem fitness putovanju. Bez obzira jeste li profesionalni sportaš, fitness entuzijast ili tek započinjete svoju vježbačku rutinu, naša recovery zona je prilagođena svim razinama kondicije.</div>
      </div>
    </div>
  )
}

export default Therapy
