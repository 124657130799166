import React from 'react';
import { useTranslation } from 'react-i18next';
import { ParallaxBanner, ParallaxProvider } from 'react-scroll-parallax';
import img from '../../assets/banner-pics/jovan-blog.webp'

const JovanBlog = () => {
  const { t } = useTranslation();

  return (
    <div>
      <ParallaxProvider>
        <ParallaxBanner
          layers={[{ image: img, speed: -15 }]}
          className="aspect-[2/1] h-72 md:h-96"
        />
        <div className="absolute top-0 right-0 z-10 pt-10 text-white flex justify-center items-center h-72 md:h-96 bg-black bg-opacity-40 w-screen m-auto">
        </div>
      </ParallaxProvider>
      <div className="max-w-4xl mx-auto p-5 pt-5">
        <h1 className="text-xl md:text-3xl font-bold text-center mb-6">{t('jovanBlog.jovanTitle')}</h1>
        <p className="mb-4">{t('jovanBlog.jovanIntroduction')}</p>
        <ul className="list-disc list-inside mb-4 text-justify">
          <li className='py-2'>{t('jovanBlog.jovanBenefit1')}</li>
          <li className='py-2'>{t('jovanBlog.jovanBenefit2')}</li>
          <li className='py-2'>{t('jovanBlog.jovanBenefit3')}</li>
          <li className='py-2'>{t('jovanBlog.jovanBenefit4')}</li>
          <li className='py-2'>{t('jovanBlog.jovanBenefit5')}</li>
        </ul>
        <p className="mb-4">{t('jovanBlog.jovanConclusion')}</p>
        <footer className="mt-8 border-t pt-4">
          <p className="text-center text-sm text-gray-600">{t('jovanBlog.jovanAuthor')}</p>
        </footer>
      </div></div>
  );
};

export default JovanBlog;
