import React, { useEffect, useState } from "react";
import "./Home.css";
import Programs from "../Programs/Programs";
import Advantages from "../../components/Advantages/Advantages";
import { Parallax } from "react-parallax";
import banner from '../../assets/banner-pics/2.webp'
import AdvantagesNew from "../../components/Advantages/AdvantagesNew";
import logo from '../../assets/pngs/desktop-logo.png'
import ScheduleBanner from "../../components/ScheduleBanner";
import Contact from "../../views/Contact/Contact";
import WomenSale from "../../components/WomenSale";
import { ParallaxBanner, ParallaxProvider } from "react-scroll-parallax";
import { useTranslation } from 'react-i18next';

const Home = () => {

  const { t, i18n } = useTranslation();
  const handleClick = () => {
    // Check the platform
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Redirect to App Store for iOS devices
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = 'https://apps.apple.com/tz/app/prometej-fitness/id6475033099';
    }
    // Redirect to Google Play for Android devices
    else if (/android/i.test(userAgent)) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.prometejfitness.app&hl=en_US&pli=1';
    }
    // Optionally handle other platforms (desktop, etc.)
    else {
      alert("Please visit this page on an iOS or Android device to download the app.");
    }
  };
  return (
    <div className="">
      {/* <div className="parallax-hero-content">
        <div className=" text-white flex justify-center items-center h-screen ">
          <button onClick={handleClick} className="bg-green-main text-sm font-bold-italic py-2 px-4 block md:hidden">PREUZMI APP</button>
        </div>
    </div> */}
      {/* {languagePicked ? null :
        <div>
          <div className="payment-backdrop fixed inset-0 faded z-50 overflow-auto bg-black bg-opacity-40 flex justify-center items-center p-4 fade-in">
            <div className="bg-gray-50 py-12 px-14 flex flex-col items-center rounded-xl">
              <p>Choose a Language</p>
              <div className="gap-2 flex flex-row">
                <button onClick={() => handleLanguageChange('en')} className="px-3 py-2 text-xl font-semibold text-white transition-colors duration-300 bg-transparent rounded hover:bg-blue-800">
                  🇬🇧
                </button>
                <button onClick={() => handleLanguageChange('me')} className="px-3 py-2 text-xl font-semibold text-white transition-colors duration-300 bg-transparent rounded hover:bg-red-600">
                  🇲🇪
                </button>
                <button onClick={() => handleLanguageChange('ru')} className="px-3 py-2 text-xl font-semibold text-white transition-colors duration-300 bg-transparent rounded hover:bg-blue-500">
                  🇷🇺
                </button>
              </div>
            </div>
          </div>
        </div>
      } */}
      <ParallaxProvider>
        <ParallaxBanner
          layers={[{ image: banner, speed: -15 }]}
          className="aspect-[2/1] h-screen"
        />
        <div className="absolute inset-0 faded z-10 text-gray-50 overflow-auto flex justify-center items-center md:p-4">
          {/* <button onClick={handleClick} className="bg-green-main text-sm font-bold-italic py-2 px-4 block md:hidden">{t('downloadNav')}</button> */}
          <div className="bg-gray-100 rounded-full bg-opacity-80 py-3 w-1/2 md:hidden">
            <img className="w-2/3 m-auto" src={logo} alt="Prometej Fitness Logo" />
          </div>
        </div>
      </ParallaxProvider>
      {/* <Parallax className="" strength={250} bgImage={banner}>
        <div className="parallax-hero-content">
          <div className=" flex justify-center items-center flex-col gap-12 relative z-40 top-96">
          </div>
        </div>
      </Parallax> */}
      {/* <img src={banner} alt="" className="min-h-screen bg-auto" /> */}
      <AdvantagesNew />
      {/* <WomenSale /> */}
      <ScheduleBanner />
      {/* <Programs /> */}
    </div>

  );
};

export default Home;
