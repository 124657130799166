import React from "react";
import "./Footer.css";
import { FaFacebookF, FaYoutube, FaInstagram, FaTiktok } from "react-icons/fa";
import logo from "../../assets/pngs/desktop-logo.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t, i18n } = useTranslation();

  const handleClick = () => {
    // Check the platform
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Redirect to App Store for iOS devices
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = 'https://apps.apple.com/tz/app/prometej-fitness/id6475033099';
    }
    // Redirect to Google Play for Android devices
    else if (/android/i.test(userAgent)) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.prometejfitness.app&hl=en_US&pli=1';
    }
    // Optionally handle other platforms (desktop, etc.)
    else {
      alert("Please visit this page on an iOS or Android device to download the app.");
    }
  };

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang)
    localStorage.setItem('language', lang)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  return (
    <footer className="footer">
      <div className="footer-info">
        <h4>{t('address')}</h4>
        <p><a href="https://maps.app.goo.gl/VFH9P2ziFXS1mEvS9" target="_blank">Ul. Kragujevačka br.7 Gornja Gorica, Podgorica</a></p>
        <h4>{t('contact')}</h4>
        <p><a href="tel:+38267109355">+382 67 109 355</a></p>
        <p><Link to="/kontakt">info@prometejfitness.me</Link></p>

        <h4>{t('workingHours')}</h4>
        <p>{t('daysOne')} 6:00 - 22:00</p>
        <p>{t('daysTwo')} 10:00 - 20:00</p>
      </div>

      <div className="social-icons">
        <h4>{t('socialMedia')}</h4>
        <div className="icons">
          <a href="https://www.facebook.com/prometejfitness" target="_blank" rel="noreferrer">
            <FaFacebookF />
          </a>
          {/* <a href="https://google.com" target="_blank" rel="noreferrer">
            <FaYoutube />
          </a> */}
          <a href="https://www.instagram.com/prometejfitness" target="_blank" rel="noreferrer">
            <FaInstagram />
          </a>
          <a href="https://www.tiktok.com/@prometejfitness" target="_blank" rel="noreferrer">
            <FaTiktok />
          </a>
        </div>
      </div>
      <div className="logo-section">
        <img className="footer-logo" alt="Prometej Fitness Logo" src={logo} />
        {/* <button className="footer-button">Dugme</button> */}
        <div>
          <div className="pt-2 md:pt-0 md:-mb-8">
            <div className="flex flex-col items-center rounded-xl">
              <p>Choose a Language</p>
              <div className="gap-2 flex flex-row">
                <button onClick={() => handleLanguageChange('en')} className="px-3 py-2 text-xl font-semibold text-white transition-colors duration-300 bg-transparent rounded hover:bg-blue-800">
                  🇬🇧
                </button>
                <button onClick={() => handleLanguageChange('me')} className="px-3 py-2 text-xl font-semibold text-white transition-colors duration-300 bg-transparent rounded hover:bg-red-600">
                  🇲🇪
                </button>
                <button onClick={() => handleLanguageChange('ru')} className="px-3 py-2 text-xl font-semibold text-white transition-colors duration-300 bg-transparent rounded hover:bg-blue-500">
                  🇷🇺
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button onClick={handleClick} className="bg-black text-sm font-bold-italic py-2 px-4 block md:hidden">{t('downloadNav')}</button>
    </footer>
  );
};

export default Footer;
