import "./App.css";
import Navbar from "./app/components/Navbar/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./app/views/Home/Home";
import Contact from "./app/views/Contact/Contact";
import ProgramPage from "./app/views/ProgramPage/ProgramPage";
import Footer from "./app/components/Footer/Footer";
import NewsPopup from './app/components/NewsPopup/NewsPopup'
import { NextStudio } from 'next-sanity/studio'
import config from "./app/sanity.config";
import News from "./app/views/News/News";
import SingleNewsPage from "./app/views/SingleNewsPage/SingleNewsPage";
import { PostProvider } from "./app/context/PostContext";
import Pricing from "./app/views/Pricing/Pricing";
import Fighting from "./app/views/Fighting/Fighting";
import BackToTopButton from "./app/components/BackToTop/backTop";
import Children from "./app/views/Children/Children";
import Therapy from "./app/views/Therapy/Therapy";
import NotFound from "./app/views/404/NotFound";
import Team from "./app/views/Team/Team";
import img from './app/assets/pngs/desktop-logo.png'
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MarijaBlog from "./app/views/Articles/Marija";
import AleksaBlog from "./app/views/Articles/Aleksa";
import JovanBlog from "./app/views/Articles/Jovan";
import Blogs from './app/views/Blogs'
import Ivan from "./app/views/Ivan";

function App() {
  const { i18n } = useTranslation()
  const [languagePicked, setLanguagePicked] = useState(false)

  useEffect(() => {
    if (!localStorage.getItem('language')) {
      setLanguagePicked(false)
    } else {
      setLanguagePicked(true)
    }
  }, [])


  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang)
    localStorage.setItem('language', lang)
    setLanguagePicked(true)
  }
  const logo = img
  return (
    // <ParallaxProvider>
    <Router className="App">
      <PostProvider>
        {/* {window.location.pathname !== '/admin' && <NewsPopup />} */}
        {window.location.pathname !== '/admin' && <Navbar />}
        {languagePicked ? null :
          <div>
            <div className="payment-backdrop fixed inset-0 faded z-50 overflow-auto bg-black bg-opacity-40 flex justify-center items-center p-4 fade-in">
              <div className="bg-gray-50 py-12 px-14 flex flex-col items-center rounded-xl">
                <p>Choose a language</p>
                <div className="gap-2 flex flex-row">
                  <button onClick={() => handleLanguageChange('en')} className="px-3 py-2 text-xl font-semibold text-black transition-colors duration-300 bg-transparent rounded hover:bg-blue-800">
                    🇬🇧
                  </button>
                  <button onClick={() => handleLanguageChange('me')} className="px-3 py-2 text-xl font-semibold text-black transition-colors duration-300 bg-transparent rounded hover:bg-red-600">
                    🇲🇪
                  </button>
                  <button onClick={() => handleLanguageChange('ru')} className="px-3 py-2 text-xl font-semibold text-black transition-colors duration-300 bg-transparent rounded hover:bg-blue-500">
                    🇷🇺
                  </button>
                </div>
              </div>
            </div>
          </div>
        }
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/kontakt" element={<Contact />} />
          <Route path="/program/:programId" element={<ProgramPage />} />
          <Route path="/cjenovnik" element={<Pricing />} />
          <Route path="/novosti" element={<News />} />
          <Route path="/tim" element={<Team />} />
          {/* <Route path="/borilacka-sekcija" element={<Fighting />} /> */}
          <Route path="/djeca" element={<Children />} />
          <Route path="/blog/marija" element={<MarijaBlog />} />
          <Route path="/blog/aleksa" element={<AleksaBlog />} />
          <Route path="/blog/jovan" element={<JovanBlog />} />
          <Route path="/blog/ivan" element={<Ivan />} />
          <Route path="/recovery" element={<Therapy />} />
          <Route path="/blog" element={<Blogs />} />
          {/* <Route path="/novosti/:slug" element={<SingleNewsPage />} /> */}
          {/* <Route path="/admin" element={<NextStudio config={config} />} /> */}
          <Route path="*" element={<NotFound />} />
        </Routes>
        {window.location.pathname !== '/admin' && <Footer />}
      </PostProvider>
      <BackToTopButton />
    </Router>
  );
}

export default App;
