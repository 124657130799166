import React from 'react'
import './NotFound.css'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <h1>404</h1>
      <div>Stranica sa ovom rutom ne postoji.</div>
      <Link to="/" style={{marginTop: '1rem'}} className='learn-more-button'>IDI NAZAD</Link>
    </div>
  )
}

export default NotFound