// TrainersList.jsx
import React from 'react';
import '../../components/TrainerCard/TrainerCard.css'; // Ensure the CSS file is imported
import TrainerCard from '../TrainerCard/TrainerCard';
import './TrainersList.css'
import marija from '../../assets/trainer-pics/marija.webp'
import jovan from '../../assets/trainer-pics/jovan.webp'
import ivan from '../../assets/trainer-pics/ivan.webp'
import pavle from '../../assets/trainer-pics/pavle.webp'
import aleksa from '../../assets/trainer-pics/aleksa.webp'
import { useTranslation } from 'react-i18next';

const TrainersList = () => {

  const { t } = useTranslation();

  const trainersData = [
    {
      id: 2,
      name: 'Marija Milović',
      title: 'Personalni Trener',
      bioShort: t('marijaBioShort'),
      bioLong: t('marijaBioLong'),
      image: marija,
    },
    {
      id: 3,
      name: 'Jovan Perunović',
      title: 'Personalni Trener',
      bioShort: t('jovanBioShort'),
      bioLong: t('jovanBioLong'),
      image: jovan,
    },
    {
      id: 4,
      name: 'Ivan Mirović',
      title: 'Personalni Trener',
      bioShort: t('ivanBioShort'),
      bioLong: t('ivanBioLong'),
      image: ivan,
    },
    {
      id: 5,
      name: 'Pavle Backović',
      title: 'Personalni Trener',
      bioShort: t('pavleBioShort'),
      bioLong: t('pavleBioLong'),
      image: pavle,
    },
    {
      id: 6,
      name: 'Aleksa Bijelović',
      title: 'Personalni Trener',
      bioShort: t('aleksaBioShort'),
      bioLong: t('aleksaBioLong'),
      image: aleksa,
    },
  ];

  return (
    <div className="trainers-container">
      {trainersData.map((trainer, index) => (
        <TrainerCard key={index} trainer={trainer} />
      ))}
    </div>
  );
};

export default TrainersList;
