const schedule = {
  name: "post",
  title: "Raspored",
  type: "document",
  fields: [
    {
      name: "name",
      title: "Post Name",
      type: "string",
      validation: Rule => Rule.required(),
    },
    {
      name: "image",
      title: "Image",
      type: "image",
      validation: Rule => Rule.required(),
      options: { hotspot: true },
      fields: [
        {
          name: "alt",
          title: "Alt",
          type: "string",
        },
      ],
    },
  ],
};

export default schedule;