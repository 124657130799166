import React, { useEffect, useState } from "react";
import { FiMenu, FiX } from "react-icons/fi";
import "../Components.css";
import mobileLogo from '../../assets/pngs/mobile-logo.png';
import desktopLogo from '../../assets/pngs/desktop-logo.png';
import { Link } from "react-router-dom";
import programsData from '../../static-data/programs'
import SignupPopup from "../SignupPopup/SignupPopup";
import { useTranslation } from "react-i18next";

const Navbar = () => {

  const { t } = useTranslation();

  const [programs, setPrograms] = useState(programsData)
  const [open, setOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false)
  const [button1Visible, setButton1Visible] = useState(false);
  const [button2Visible, setButton2Visible] = useState(false);
  const [button3Visible, setButton3Visible] = useState(false);
  const [button4Visible, setButton4Visible] = useState(false);
  const [button5Visible, setButton5Visible] = useState(false);
  const [button6Visible, setButton6Visible] = useState(false);
  const [button7Visible, setButton7Visible] = useState(false);
  const [button8Visible, setButton8Visible] = useState(false);
  const onClose = () => {
    setShowPopup(false)
  }

  const isMobile = window.innerWidth <= 768;

  const closeMenu = () => {
    setOpen(false);
    setDropdownOpen(false);
  }

  const handleProgramiClick = (event) => {
    if (isMobile) {
      event.preventDefault();
      setDropdownOpen(!dropdownOpen);
    }
  }
  useEffect(() => {
    const button1Timer = setTimeout(() => {
      setButton1Visible(true);
    }, Math.random() * 1500);

    const button2Timer = setTimeout(() => {
      setButton2Visible(true);
    }, Math.random() * 1500);
    const button3Timer = setTimeout(() => {
      setButton3Visible(true);
    }, Math.random() * 1500);

    const button4Timer = setTimeout(() => {
      setButton4Visible(true);
    }, Math.random() * 1500);

    const button5Timer = setTimeout(() => {
      setButton5Visible(true);
    }, Math.random() * 1500);

    const button6Timer = setTimeout(() => {
      setButton6Visible(true);
    }, Math.random() * 1500);

    const button7Timer = setTimeout(() => {
      setButton7Visible(true);
    }, Math.random() * 1500);

    const button8Timer = setTimeout(() => {
      setButton8Visible(true);
    }, Math.random() * 1500);

    return () => {
      clearTimeout(button1Timer);
      clearTimeout(button2Timer);
      clearTimeout(button3Timer);
      clearTimeout(button4Timer);
      clearTimeout(button5Timer);
      clearTimeout(button6Timer);
      clearTimeout(button7Timer);
      clearTimeout(button8Timer);
    };
  }, []);

  const handleClick = () => {
    // Check the platform
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Redirect to App Store for iOS devices
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = 'https://apps.apple.com/tz/app/prometej-fitness/id6475033099';
    }
    // Redirect to Google Play for Android devices
    else if (/android/i.test(userAgent)) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.prometejfitness.app&hl=en_US&pli=1';
    }
    // Optionally handle other platforms (desktop, etc.)
    else {
      alert("Please visit this page on an iOS or Android device to download the app.");
    }
  };
  return (
    <header className="">
      {/* <SignupPopup open={showPopup} onClose={onClose} /> */}
      <div className="logo-container">
        <Link to='/' ><img className="mobile-logo" src={mobileLogo} alt="Prometej Fitness Logo" /></Link>
        <Link to='/' ><img className={`button-appear desktop-logo ${button1Visible ? 'visible' : ''}`} src={desktopLogo} alt="Prometej Fitness Logo" /></Link>
        <div className="menu-icon" onClick={() => setOpen(!open)}>
          <FiMenu size={"25px"} />
        </div>
      </div>
      <nav className={open ? "nav-links mobile-nav-links" : "nav-links"}>
        <div className="menu-icon close-icon" onClick={closeMenu}>
          <FiX size={"25px"} />
        </div>
        <div className="nav-links-options">
          {/* <Link onClick={closeMenu} to="/">Početna</Link> */}
          <div className="dropdown" onClick={handleProgramiClick}>
            {/* <span className={`button-appear programi ${button2Visible ? 'visible' : ''}`}>Programi</span> */}
            <div className={dropdownOpen ? "dropdown-content" : "dropdown-content hidden"}>
              {programs.map((el) => (
                <Link className="programs-link" key={el.id} onClick={closeMenu} to={`/program/${el.id}`}>{el.title}</Link>
              ))}
            </div>
          </div>
          <Link onClick={closeMenu} className={`button-appear ${button4Visible ? 'visible' : ''}`} to="/cjenovnik">{t('pricingNav')}</Link>
          <Link onClick={closeMenu} className={`button-appear ${button3Visible ? 'visible' : ''}`} to="/djeca">{t('childrenNav')}</Link>
          <Link onClick={closeMenu} className={`button-appear ${button5Visible ? 'visible' : ''}`} to="/tim">{t('teamNav')}</Link>
          <Link onClick={closeMenu} className={`button-appear ${button5Visible ? 'visible' : ''}`} to="/blog">{t('blogNav')}</Link>
          {/* <Link onClick={closeMenu} className={`button-appear ${button6Visible ? 'visible' : ''}`} to="/borilacka-sekcija">Fighting</Link> */}
          <Link onClick={closeMenu} className={`button-appear ${button7Visible ? 'visible' : ''}`} to="/kontakt">{t('contactNav')}</Link>
          {/* <Link onClick={closeMenu} className={`button-appear md:hidden ${button7Visible ? 'visible' : ''}`} to="/kontakt">Kontakt</Link> */}
        </div>
      </nav>
      <div className={`${button8Visible ? 'visible' : ''}`}>
        <button className="text-white bg-green-main hover:bg-green-800 ease-in text-xs font-bold-italic py-2 px-4 md:block hidden" onClick={handleClick}>{t('downloadNav')}</button>
      </div>
    </header>
  );
};

export default Navbar;
