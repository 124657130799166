// {/* <img src="https://img.freepik.com/premium-photo/woman-holding-barbell-shoulders-gym_651396-1604.jpg" alt="" /> */}
// Advantages.jsx
import React, { useEffect, useRef } from "react";
import { FaCheckCircle } from "react-icons/fa";
import "./AdvantagesNew.css";
import img from '../../assets/pngs/banner.webp'

function Advantages() {
  const advantagesRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('advantages-animate');
          }
        });
      },
      {
        root: null,
        rootMargin: "100px",
        threshold: 0.15
      }
    );

    if (advantagesRef.current) {
      observer.observe(advantagesRef.current);
    }

    return () => {
      if (advantagesRef.current) {
        observer.unobserve(advantagesRef.current)
      }
    };
  }, []);
  const pros = [
    "Prijateljska i podržavajuća zajednica",
    "Vrhunska oprema",
    "Visoko obučeni profesionalci",
    "Otvoreno 24/7",
    "Razni fitness programi",
    "Programi za Vaše mališane",
    "Mjesto za druženje",
    "Centar sa mnoštvom aktivnosti",
    "Parking mjesto za Vaš automobil ili motor",
  ];

  return (
    <div ref={advantagesRef} className="advantages-container item">
      <div className="advantages-description">
        <div className="advantages-title">
          SVE ŠTO PROMETEJ NUDI
        </div>
        <div className="advantages-body">
          Posjedujemo najmoderniju opremu, stručno osoblje i motivirajuću zajednicu, naša teretana je idealno mjesto za ostvarivanje fitness ciljeva.
        </div>
      </div>
      <div className="advantages-wrapper">
        <img
          className="advantages-img"
          src={img}
          alt="Fitness center"
        />
        <div className="pros-container">
          {pros.map((pro, index) => (
            <div key={index} className="pro">
              <p>• {pro}</p>
            </div>
          ))}
        </div>
      </div></div>
  );
}

export default Advantages;
