import React from 'react'
import './Children.css'
import birthday from '../../assets/pngs/birthday.png'
import swing from '../../assets/pngs/swing.png'
import dumbbell from '../../assets/pngs/dumbbell.png'
import banner from '../../assets/images/playground.webp'
import { ParallaxBanner, ParallaxProvider } from 'react-scroll-parallax'
import { useTranslation } from 'react-i18next'

const Children = () => {

  const { t } = useTranslation();
  
  return (
    <div className='children-wrapper'>
      <ParallaxProvider>
        <ParallaxBanner
          layers={[{ image: banner, speed: -15 }]}
          className="aspect-[2/1] h-72 md:h-96"
        />
        <div className="absolute top-0 right-0 z-10 pt-10 text-white flex justify-center items-center h-72 md:h-96 bg-black bg-opacity-40 w-screen m-auto">
          <div className="text-xl md:text-4xl font-bold-italic block">{t('childrenBanner')}</div>
        </div>
      </ParallaxProvider>
      <div className="pricing-page-about md:pt-10">
        <h2 className='pt-3 md:pt-1'>{t('childrenProgram')}</h2>
        <div>
        {t('childrenDescOne')}
          <span className='text-green-500'> {t('childrenDescGreenOne')}</span>.
          {t('childrenDescTwo')} <span className='text-green-500'><a href="mailto:igraonica@prometejfitness.me">{t('childrenDescGreenTwo')}</a></span> {t('or')} <span className='text-green-500'><a href="tel:+38267109358">{t('childrenDescGreenThree')}</a></span>.
        </div>
      </div>
      <div className="children-plans pb-10 pt-5 md:pt-10">
        <div className="children-plan">
          <img alt='Swing Icon' src={swing} />
          <div>{t('childrenIconTextOne')}</div>
        </div>
        <div className="children-plan">
          <img alt='Dumbell Icon' src={dumbbell} />
          {t('childrenIconTextTwo')}
        </div>
        <div className="children-plan">
          <img alt='Birthday Icon' src={birthday} />
          <div>{t('childrenIconTextThree')}</div>
        </div>
      </div>
    </div>
  )
}

export default Children
