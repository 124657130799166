 const programsData = [
  {
    id: 1,
    title: 'Cardio Blast',
    description: 'Feel the burn and transform your body with our dynamic workout! Our high-energy sessions combine cardio, strength training, and core exercises for a complete fitness experience. Led by experienced trainers, youll sweat through a variety of movements and challenges, targeting every muscle group. Push yourself to the limit and achieve your fitness goals in just 60 minutes. Get ready to unleash your inner athlete!',
    img: 'https://www.fitmotherproject.com/wp-content/uploads/2018/12/shutterstock_776152228-min.jpg',
    goodToKnows: { age: '5-55', duration: '35 MINUTA', weekly: '4' }
  },
  {
    id: 2,
    title: 'Muscle Max',
    description: 'Feel the burn and transform your body with our dynamic workout! Our high-energy sessions combine cardio, strength training, and core exercises for a complete fitness experience. Led by experienced trainers, youll sweat through a variety of movements and challenges, targeting every muscle group. Push yourself to the limit and achieve your fitness goals in just 60 minutes. Get ready to unleash your inner athlete!',
    img: 'https://kajabi-storefronts-production.kajabi-cdn.com/kajabi-storefronts-production/blogs/13549/images/a6TqHWyCQemfDwBH0CVJ_UNADJUSTEDNONRAW_thumb_566.jpg',
    goodToKnows: { age: '5-55', duration: '35 MINUTA', weekly: '4' }
  },
  {
    id: 3,
    title: 'Yoga Fusion',
    description: 'Feel the burn and transform your body with our dynamic workout! Our high-energy sessions combine cardio, strength training, and core exercises for a complete fitness experience. Led by experienced trainers, youll sweat through a variety of movements and challenges, targeting every muscle group. Push yourself to the limit and achieve your fitness goals in just 60 minutes. Get ready to unleash your inner athlete!',
    img: 'https://media.self.com/photos/5a0f4ecd7753b02dca520435/4:3/w_2560%2Cc_limit/new-years-challenge-tamara-3.jpg',
    goodToKnows: { age: '5-55', duration: '35 MINUTA', weekly: '4' }
  },
  {
    id: 4,
    title: 'HIIT Intensity',
    description: 'Feel the burn and transform your body with our dynamic workout! Our high-energy sessions combine cardio, strength training, and core exercises for a complete fitness experience. Led by experienced trainers, youll sweat through a variety of movements and challenges, targeting every muscle group. Push yourself to the limit and achieve your fitness goals in just 60 minutes. Get ready to unleash your inner athlete!',
    img: 'https://img.freepik.com/free-vector/vibrant-wide-banner-with-diagonal-stripes-pattern_1017-28366.jpg?w=2000',
    goodToKnows: { age: '5-55', duration: '35 MINUTA', weekly: '4' }
  },
  {
    id: 5,
    title: 'Core Sculpt',
    description: 'Feel the burn and transform your body with our dynamic workout! Our high-energy sessions combine cardio, strength training, and core exercises for a complete fitness experience. Led by experienced trainers, youll sweat through a variety of movements and challenges, targeting every muscle group. Push yourself to the limit and achieve your fitness goals in just 60 minutes. Get ready to unleash your inner athlete!',
    img: 'https://bod-blog-assets.prod.cd.beachbodyondemand.com/bod-blog/wp-content/uploads/2022/05/17121224/aerobic-capacity-600-running.png',
    goodToKnows: { age: '5-55', duration: '35 MINUTA', weekly: '4' }
  },
  {
    id: 6,
    title: 'Pilates Power',
    description: 'Feel the burn and transform your body with our dynamic workout! Our high-energy sessions combine cardio, strength training, and core exercises for a complete fitness experience. Led by experienced trainers, youll sweat through a variety of movements and challenges, targeting every muscle group. Push yourself to the limit and achieve your fitness goals in just 60 minutes. Get ready to unleash your inner athlete!',
    img: 'https://zinginstruments.com/wp-content/uploads/2020/01/Woman-with-exercise-ball.png',
    goodToKnows: { age: '5-55', duration: '35 MINUTA', weekly: '4' }
  }
]
export default programsData